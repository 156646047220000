<template>
  <el-select v-model="value" :multiple="multiple" :size="megaMode ? '' : 'mini'" filterable remote reserve-keyword :remote-method="remoteMethod" placeholder="请输入关键词" @change="codeSelectChane" :disabled="disabled" style="width:100%" :loading="loading">
    <el-option v-for="(item,index) in options" :key="index" :value="item.code">
      <span>{{item.name}}</span><span style="margin: 0 10px">{{item.code}}</span><span>{{item.pinyin}}</span>
    </el-option>
  </el-select>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs, watch } from "vue";
import { props } from "./codeProps";
import vPinyin from '@/utils/vue-py'
import { cloneDeep } from "lodash"
import axios from "@/axios/globalaxios";

export default defineComponent({
  name: "index",
  props: props,
  setup(props, ctx) {
    onMounted(() => {
      getCodeList()
    })
    watch(() => [props.codeList, props.codeType], ([newVal, newType]) => {
      if (newVal) {
        data.value = newVal
        if (props.megaMode) {
          data.options = []
          data.value = props.codeList
          data.codeName = props.codeType === '0' ? 'all' : props.codeType === '1' ? 'shares' : props.codeType === '2' ? 'bond' : 'forward'
        }
      }
      // if (newType){
      //   getCodeList()
      // }

    })
    let data = reactive({
      options: [],
      value: [],
      codeName:'',
      list: [],
      searchList: [],
      loading: false,
    })
    const remoteMethod = (val) => {
      if (val !== '') {
        data.loading = true;
        let codeList = JSON.parse(localStorage.getItem(data.codeName))
        if (codeList && codeList.length > 0) {
          data.list = codeList.map(v => { return { code: v.fgsid, name: v.fname, pt: v.pt, vt: v.vt, pinyin: vPinyin.chineseToPinYin(v.fname) } })
        }
        setTimeout(() => {
          data.loading = false;
          data.options = data.list.filter(item => {
            return item.code.toUpperCase().indexOf(val.toUpperCase()) > -1 || item.name.indexOf(val) > -1 || item.pinyin.toUpperCase().indexOf(val.toUpperCase()) > -1;
          });
        }, 600);
      } else {
        data.options = [];
      }
    }
    let getCodeList = () => {
      data.value = props.codeList
      let codeName = props.codeType === '0' ? 'all' : props.codeType === '1' ? 'shares' : props.codeType === '2' ? 'bond' : 'forward'
      let codeList = []
      if (props.codeType === '0' || props.codeType === '1' || props.codeType === '2' || props.codeType === '3') {
        
        axios.post('/tradelab/transaction/findStopwatchByFtype', { type: props.codeType }).then(res => {
          if (res.data.code === '200') {
            codeList = res.data.data.stopwatches
            if (codeList && codeList.length > 0) {
              data.list = codeList.map(v => { return { code: v.fgsid, name: v.fname, pt: v.pt, vt: v.vt, pinyin: vPinyin.chineseToPinYin(v.fname) } })

            }
          }
        })
      } else if (props.codeType === '4') {
        axios.post('/tradelab/afterTraderPrice/findStopwatchByType ').then(res => {
          if (res.data.code === '200') {
            codeList = res.data.data
            if (codeList && codeList.length > 0) {
              data.list = codeList.map(v => { return { code: v.fgsid, name: v.fname, pt: v.pt, vt: v.vt, pinyin: vPinyin.chineseToPinYin(v.fname) } })

            }
          }
        })
      } else {
        codeList = JSON.parse(localStorage.getItem(codeName))
        if (codeList && codeList.length > 0) {
          data.list = codeList.map(v => { return { code: v.fgsid, name: v.fname, pt: v.pt, vt: v.vt, pinyin: vPinyin.chineseToPinYin(v.fname) } })

        }
      }
      // if (props.codeType === '4') {
      //   axios.post('/tradelab/afterTraderPrice/findStopwatchByType').then(res => {
      //     if (res.data.code === '200') {
      //       codeList = res.data.data
      //       if (codeList && codeList.length > 0) {
      //         data.list = codeList.map(v => { return { code: v.fgsid, name: v.fname, pt: v.pt, vt: v.vt, pinyin: vPinyin.chineseToPinYin(v.fname) } })

      //       }
      //     }
      //   })
      // } else {
      //   axios.post('/tradelab/transaction/findStopwatchByFtype', { type: null }).then(res => {
      //     if (res.data.code === '200') {
      //       codeList = res.data.data.stopwatches
      //       if (codeList && codeList.length > 0) {
      //         data.list = codeList.map(v => { return { code: v.fgsid, name: v.fname, pt: v.pt, vt: v.vt, pinyin: vPinyin.chineseToPinYin(v.fname) } })

      //       }
      //     }
      //   })
      // }
      if (props.showDefault) {
        let name = 'type' + props.codeType
        data.value = sessionStorage.getItem(name) || data.value
        codeSelectChane()
      }
    }

    const codeSelectChane = () => {
      let name = 'type' + props.codeType
      sessionStorage.setItem(name, data.value)
      let arr = cloneDeep(data.value)
      ctx.emit('codeSelectChane', arr)
    }
    return {
      ...toRefs(data),
      remoteMethod,
      codeSelectChane,
    }
  }
})
</script>

<style scoped>
</style>
