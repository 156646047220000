/**
 * @author wuDada
 * @class
 * @name 证券搜索
 * @description 功能类 组件（prop）参数
 * @param {String} codeType  默认为0 查询全部，传1，2，3分别为股票，债券，期货
 * @param {Boolean} multiple  是否开启多选 默认多选
 */
export const props = {
    codeType:{
        type: String,
        default: '0'  //  传 1，2，3 默认查全部
    },
    multiple:{
        type:Boolean,
        default: true // 是否多选 默认多选
    },
    codeList:{
        // type: Object,
        default:{}
    },
    showDefault:{
      type: Boolean,
      default: true
    },
    megaMode: {
        type: Boolean,
        default: false, // 是否大赛 默认否
    },
    disabled: {
        type: Boolean,
        default: false, // 是否禁用 默认否
    }
}
